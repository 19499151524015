import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormValidators } from '@spartacus/storefront';
import { ResetPasswordComponent, ResetPasswordComponentService } from '@spartacus/user/profile/components';

@Component({
  selector: 'app-custom-reset-password',
  templateUrl: './custom-reset-password.component.html',
  styleUrls: ['./custom-reset-password.component.scss']
})
export class CustomResetPasswordComponent extends ResetPasswordComponent implements OnInit {

  public passwordChecked: boolean = false;

  constructor(service: ResetPasswordComponentService) {
    super(service);
  }

  ngOnInit() {
    /*
     * Removes original Spartacus password regex validation
     * in order to implement the custom password strength
     * validation.
     */
    this.form.controls['password'].removeValidators(CustomFormValidators.passwordValidator);
    this.form.controls['password'].addValidators(Validators.minLength(8));
  }

  public checkPassword($event: boolean) {
    this.passwordChecked = $event;
  }

}
