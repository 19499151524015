<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<form
  *ngIf="token$ | async as token"
  (ngSubmit)="onSubmit(token)"
  [formGroup]="form"
  class="reset-password-formular"
>

  <h3 role="subtitle">{{ 'customResetPassword.title' | cxTranslate }}</h3>

  <div class="password-hint-container" role="alert">
    <app-icon
      [iconType]="'ERROR'"
      iconSize="EXTRA_LARGE"
    ></app-icon>
    <p class="password-hint-text-area">
      {{ 'customResetPassword.passwordHint' | cxTranslate }}
    </p>
  </div>

  <label>
    <span class="label-content">{{
      'customResetPassword.formular.passwordLabel' | cxTranslate
    }}</span>
    <input
      autocomplete="off"
      aria-required="true"
      class="form-control"
      type="password"
      placeholder="{{ 'customResetPassword.formular.passwordPlaceholder' | cxTranslate }}"
      formControlName="password"
    />
    <cx-form-errors
      aria-live="assertive"
      aria-atomic="true"
      [control]="form.get('password')"
      [prefix]="'customResetPassword.formular.errors'"
    ></cx-form-errors>
    <app-kurz-password-checker [control]="form.get('password')" (validPassword)="checkPassword($event)"></app-kurz-password-checker>
  </label>

  <label>
    <span class="label-content">{{
      'customResetPassword.formular.confirmPasswordLabel' | cxTranslate
    }}</span>
    <input
      aria-required="true"
      autocomplete="off"
      class="form-control"
      type="password"
      placeholder="{{ 'customResetPassword.formular.confirmPasswordPlaceholder' | cxTranslate }}"
      formControlName="passwordConfirm"
    />
    <cx-form-errors
      aria-live="assertive"
      aria-atomic="true"
      [control]="form.get('passwordConfirm')"
      [prefix]="'customResetPassword.formular.errors'"
    ></cx-form-errors>
  </label>

  <app-button
    [label]="'customResetPassword.formular.saveButton' | cxTranslate"
    [disabled]="form.invalid || !passwordChecked"
    [colorTheme]="['PRIMARY', 'BASE', {}]"
    size="MEDIUM"
  ></app-button>
</form>

<app-button
  [routerLink]="{ cxRoute: 'login' } | cxUrl"
  class="to-login-button"
  [label]="'customResetPassword.toLoginButton' | cxTranslate "
  [colorTheme]="['SECONDARY', 'WHITE']"
  [buttonSize]="'MEDIUM'"
></app-button>
